<template>
  <b-card>
    <b-card-title>Création d'un type de device</b-card-title>
    <b-form
      @submit.prevent
      @submit="createDevicetype(devicetypeData)"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Nom"
            label-for="h-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-name"
              v-model="devicetypeData.name"
              type="text"
              placeholder="Nom"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Commentaire"
            label-for="h-comment"
            label-cols-md="4"
          >
            <b-form-input
              id="h-comment"
              v-model="devicetypeData.comment"
              type="text"
              placeholder="Commentaire"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Date de création"
            label-for="h-launch_date"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="h-launch_date"
              v-model="devicetypeData.launch_date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="date"
              required
              hide-header
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Date de fin (n'impacte rien)"
            label-for="h-end_date"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="h-end_date"
              v-model="devicetypeData.end_date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="date"
              required
              hide-header
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Sauvegarder
          </b-button>
          <b-link
            :to="{ name: 'device-types' }"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-primary"
            >
              Annuler
            </b-button>
          </b-link>
        </b-col>
      </b-row>

    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
  BCard, BCardTitle, BLink, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BCardTitle,
    BLink,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      devicetypeData: {},
    }
  },
  mounted() {
  },
  methods: {
    async createDevicetype(devicetypeData) {
      const newDevicetype = devicetypeData
      await this.$store.dispatch('devicetype/createDevicetype', newDevicetype)
        .then(() => this.$router.push({ name: 'device-types' }))
    },
  },
}
</script>
